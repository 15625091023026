import './index.scss';
import { ReactComponent as IconClose } from '../../assets/images/close.svg';

function Modal(props: any) {
    return (
        <div className="Modal" onClick={() => (props.onDismiss !== undefined ? props.onDismiss() : false)}>
            <div className="ModalBody">
                {props.onDismiss !== undefined && (
                    <IconClose width={16} height={16} />
                )}
                {props.title && (
                    <p className="ModalTitle">{props.title}</p>
                )}
                {typeof props.children === 'string' && (
                    <p dangerouslySetInnerHTML={{__html: props.children}}></p>
                )}
                {typeof props.children !== 'string' && (
                    <>{props.children}</>
                )}
            </div>
        </div>
    )
}

export default Modal;