import './index.scss';

function Busy() {
  return (
    <div className="Busy">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  );
}

export default Busy;
