import { createContext } from "react";

export default class EventHandler {
    listeners: any = {};

    constructor() {

        window.addEventListener('online', () => {
            this.trigger('network:change', true);
        });

        window.addEventListener('offline', () => {
            this.trigger('network:change', false);
        });

    }

    trigger(name: any, ...payload: any[]) {
        if (name in this.listeners) {
            this.listeners[name].forEach((cb: any) => {
                cb.apply(null, payload);
            });
        }
    }

    on(name: any, cb: any) {
        if (!(name in this.listeners)) {
            this.listeners[name] = [];
        }
        this.listeners[name].push(cb);
    }

    off(name: any, cb: any) {
        if (name in this.listeners) {
            let idx;
            while ((idx = this.listeners[name].indexOf(cb)) > -1) {
                delete this.listeners[name][idx];
            }
        }
    }
}

export const EventContext = createContext(null as any);