import { useCallback, useContext, useEffect } from 'react';
import './index.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../Contexts/Authentication';
import { DataContext } from '../../Contexts/Data';

function Public() {
  const authentication = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const dataLayer = useContext(DataContext);
  dataLayer.getApi().registerNavigate(navigate);
  
  const installed = useCallback(() => {
    navigate('/installed');
  }, [navigate]);
  
  useEffect(() => {
    let install = localStorage.getItem('DEBUG') === null && process.env.NODE_ENV === 'production' && !window.matchMedia('(display-mode: standalone)').matches;
    if (install && window.location.pathname.indexOf('/install') === -1) {
      navigate('/install');
      return;
    }
    if (authentication.check()) {
      navigate('/');
    }
  }, [authentication, navigate]);


  useEffect(() => {
    window.addEventListener('appinstalled', installed);
    return () => {
      window.removeEventListener('appinstalled', installed);
    }
  }, [installed]);

  return (
    <div className={`PublicLayout`}>
      <Outlet />
    </div>
  );
}

export default Public;
