import { ReactNode } from 'react';
import './index.scss';

type Props = {
    children: string | JSX.Element | JSX.Element[] | ReactNode,
    onDismiss: () => void | undefined,
    title: string | undefined,
}
  
function Notification({children, title, onDismiss} : Props) {
  return (
    <div className="Notification" onClick={() => (onDismiss !== undefined ? onDismiss() : false)}>
        {title !== undefined && (
            <p className="NotificationTitle">{title}</p>
        )}
        {children}
    </div>
  );
}

export default Notification;
