export function registerHandlers(cb: any) {
    const handle = (e: any) => {
        cb(e);
    };
    window.addEventListener('error', handle);
    window.addEventListener('unhandledrejection', handle);
    return handle
}

export function unRegisterHandlers(handle: any) {
    window.removeEventListener('error', handle);
    window.removeEventListener('unhandledrejection', handle);
}